<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1 v-if="editMode">{{ $t("user-dept-detail-title") }}</h1>
      <h1 v-else>{{ $t("user-dept-register-title") }}</h1>
<!--      <div>
        &lt;!&ndash;        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >&ndash;&gt;
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>-->
    </div>
    <div class="contents userRt">
      <div class="box one">
        <div class="flex">
          <p class="bold">{{ $t("department-date-id") }}</p>
          <p>{{ departmentId }}</p>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("department-date-regdate") }}</p>
          <p>{{ editMode ? createdAt : nowDate }}</p>
        </div>
        <div></div>
        <div class="flex">
          <p class="bold">{{ $t("department-date-name") }}<span>*</span></p>
          <input type="text" v-model="name" />
        </div>
        <div class="flex">
          <p class="bold">{{ $t("department-date-status") }}<span>*</span></p>
          <select class="small" v-model="status">
            <option value="Used">{{ $t("department-date-used") }}</option>
            <option value="Stop">{{ $t("department-date-stop") }}</option>
            <!-- <option value="Delete">삭제</option> -->
          </select>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("department-date-phone") }}<span>*</span></p>
          <input
              type="text"
              :value="phoneNumber"
              @input="bindNumber"
          />
        </div>
        <div></div>
        <div class="flexL">
          <p class="bold">{{ $t("department-date-addr") }}<span>*</span></p>
          <input type="text" disabled v-model="address" />
          <input type="text" class="margin6" v-model="addressDetail" />
          <button class="small margin6" @click="execDaumPostcode">
            {{ $t("btn-addr-search") }}
          </button>
        </div>

        <div class="buttonWrap">
          <button class="large left" v-if="editMode" @click="handleDelete">
            {{ $t("btn-delete") }}
          </button>
          <div>
            <button class="point large" @click="submit">{{ $t("btn-confirm") }}</button>
            <button class="large" @click="$router.go(-1)">
              {{ $t("btn-cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { format } from "@/mixins/format";
import { sido } from "@/util/address";
import {
  fetchDepartment,
  createDepartment,
  updateDepartment,
  deleteDepartment,
} from "@/api/department";
import {
  fetchUserList,
} from "@/api/user";
export default {
  name: "TeamRegister",
  mixins: [format],
  data() {
    return {
      editMode: false,
      nowDate: "",
      moment: moment,
      createdAt: "",
      departmentId: "",
      name: "",
      status: "Used",
      phoneNumber: "",
      address: "",
      addressDetail: "",
    };
  },
  created() {
    if (this.$route.query.departmentId) {
      this.editMode = true;
      this.departmentId = this.$route.query.departmentId;
      this.getDepartment();
    }
    this.nowDate = moment().format("YYYY.MM.DD HH:mm:ss");
  },
  watch : {
    phoneNumber(val){
      let check = /^[0-9]+$/;
      if (!check.test(val)) {
        this.phoneNumber = val.replace(/[^0-9]/g,'');
      }
    },
  },
  mounted() {},
  computed: {
    ...mapState({
      accountId: state => state.store.accountId,
    }),
  },
  methods: {
    bindNumber(event){
      this.phoneNumber = event.target.value;
    },
    handleRefresh() {
      this.$router.go();
    },
    execDaumPostcode() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          let jibunArray = data.jibunAddress.split(" "); // 지번 배열화
          jibunArray[0] = sido[jibunArray[0]]; //  배열[0](시 이름)을 시 풀네임으로 변경
          this.oldAddress = jibunArray.join(" "); //        변경된 주소 합침

          let addArray = data.address.split(" ");
          addArray[0] = sido[addArray[0]];
          this.address =
              addArray.join(" ") +
              (data.buildingName ? "(" + data.buildingName + ")" : "");

          this.postcode = data.zonecode;
        },
      }).open();
    },
    async handleDelete() {
      let result = confirm(this.$t("alert-message-check-detp-delete"));
      if (result == false) {
        return;
      }
      let params = {
        pageNumber: 0,
        departmentId: this.departmentId,
        statuses: "Used",
      };
      let term = "";
      let userCount = 0;
      await fetchUserList(params, term).then((res) => {
        userCount = res.data.data.total;
      });
      if (userCount != 0) {
        return alert(this.$t("alert-message-check-dept-delete-fail"));
      } else {
        deleteDepartment(this.departmentId).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-delete"));
            this.$router.go(-1);
          }
        });
      }
    },
    getDepartment() {
      fetchDepartment(this.departmentId).then((res) => {
        this.name = res.data.data.name;
        this.status = res.data.data.status;
        this.phoneNumber = res.data.data.phoneNumber;
        this.address = res.data.data.address;
        this.addressDetail = res.data.data.addressDetail;
        this.createdAt = moment(res.data.data.registerDate).format(
            "YYYY.MM.DD HH:mm:ss"
        );
      });
    },
    submit() {
      let data = {
        accountId: this.accountId,
        name: this.name,
        phoneNumber: this.phoneNumber,
        status: this.status,
        address: this.address,
        addressDetail: this.addressDetail,
      };

      if(this.name == null || this.name.trim() == "") {
        alert(this.$t("alert-message-check-dept-name-value"));
        return;
      }

      if(this.address == null || this.address.trim() == "") {
        alert(this.$t("alert-message-check-addr"));
        return;
      }

      if(this.phoneNumber == null ||this.phoneNumber.trim() == "") {
        alert(this.$t("alert-message-check-call"));
        return;
      }

      if (this.editMode) {
        updateDepartment(this.departmentId, data).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-data-save"));
            this.$router.go(-1);
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      } else {
        createDepartment(data).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-data-save"));
            this.$router.go(-1);
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
  },
};
</script>
